<template>
  <div>
    <headerSection mainMenu="cashback"></headerSection>
    <div class="x-main-content" :style="{ 'height':'100vh','background-image': `url(${require('../assets/images/Shiba-BG.jpg')})`}"><!--,'background-size':'900px 700px','background-image': `url(${require('../assets/images/Shiba-BG.jpg')})` -->
      <div id="main_content_recommend" class="container">
    <section class="row x-category-index" >
        <div class="col-sm-12 col-lg-12 col-12 recommend_section" style="padding-top:25px;">
            <fieldset class="form-group text-center mb-0 px-1">
                <div class="row mt-2 mb-0 mb-4 justify-content-md-center">
                    <div class="col-sm-12 col-lg-12 col-12" style="padding-top: 45px;">
                        <h2 class="text-center mb-3" style="font-weight: 500;color:#fff;">คืนยอดเล่น</h2>
                        <!--
                        <fieldset class="form-group">
                            <div class="row mt-3 mb-0 justify-content-md-center">
                                    <div class="col-6">
                                        <a href="https://social-plugins.line.me/lineit/share?url=https://auto.shib1688.net/register?uplineid=NjI1NDI=" target="_blank" class="btn btn-line btn-sm btn-block"><img src="https://auto.shib1688.net/assets/images/icon-line.png" style="max-width: 15px;display: inline-block;margin-right: 3px;"> แชร์ </a>
                                    </div>
                                    <div class="col-6">
                                        <a href="https://www.facebook.com/sharer/sharer.php?u=https://auto.shib1688.net/register?uplineid=NjI1NDI=" target="_blank" class="btn btn-facebook btn-sm btn-block"><i class="fa fa-facebook-square mr-1" aria-hidden="true"></i> แชร์ </a>
                                    </div>
                            </div>
                        </fieldset>
                        -->
                        <img src="https://firebasestorage.googleapis.com/v0/b/fivem-888.appspot.com/o/images%2F-N2gkoZIlRj6pJ8pljlo.png?alt=media&token=e060187c-95dd-4b67-8678-63bf3167b5fd" class="-cover-img img-fluid">
                    </div>
                </div>
            </fieldset>

            <div class="text-title text-center" style="font-family: Kanit;">ยอดเล่น</div>
            <div class="row no-gutters">
                <div class="col-6">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;">ฝาก</p>
                        <p class="mb-0">{{ numberWithCommas(this.cashback.deposit )}}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;">ถอน</p>
                        <p class="mb-0">{{ numberWithCommas(this.cashback.withdraw )}}</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;">รายได้ทั้งหมด</p>
                        <p class="mb-0">{{ numberWithCommas(this.cashback.total )}}</p>
                    </div>
                </div>
                <div class="col-12 text-center" style="padding-top:22px;">
                    <a href="javascript:void(0)" @click="moveCashbackToWallet()" class="text-white js-account-approve-aware btn" :style="checkStyle()" data-toggle="modal">
                        <div style="font-size: 14px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">โอนเงินเข้ากระเป๋าหลัก</div>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-12 col-12 recommend_section">
            <div v-html="nl2br(detail)" style="text-align: center;padding:30px;font-size: 16px;font-family: Kanit;"></div>
        </div>
    </section>
</div>
</div>
<footerSection></footerSection>
</div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'
import moment from 'moment-timezone'
import router from './../router'
const axios = require('axios').default
export default {
  name: 'Cashback',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  data: function () {
    return {
      cashback: [],
      detail:'',
    }
  },
  methods: {
    nl2br (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return ''
      }
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    moveCashbackToWallet () {
      if (this.cashback.total && this.cashback.key) {
        this.$store.commit('user/SET_LOADING', true)
        axios.get(`https://api.fivem888.biz/qcashback/cashback/${this.cashback.key}`)
        setTimeout(() => {
          this.getCashBack()
          this.$store.commit('user/SET_LOADING', false)
        }, 2000)
      }
    },
    checkStyle () {
      if (this.cashback && this.cashback.total > 0) {
        return 'background: linear-gradient(180deg,#fbc844,#f88012);height: 43px;min-width:120px;padding-left: 24px;padding-right: 24px;padding-top: 0.8em;'
      } else {
        return 'background: linear-gradient(180deg,#cccccc,#cccccc);height: 43px;min-width:120px;padding-left: 24px;padding-right: 24px;padding-top: 0.8em;'
      }
    },
    numberWithCommas (x) {
	  if (!x) x = 0
      return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/')
      }
    },
    async getCashBack () {
      this.detail = (await firebase.database().ref(`/cashback/detail`).once('value')).val();
      const user = this.getUserId()
      if (user) {
        firebase.firestore().collection('cashback').where('date', '==', moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).where('userkey', '==', user.key).where('status', '==', false).get().then((snapshot) => {
          if (!snapshot.size) this.cashback = []
          snapshot.forEach((doc) => {
            const data = doc.data()
            data.key = doc.id
            this.cashback = data
          })
        })
      }
    }
  },
  mounted () {
    this.scrollToTop()
    this.getCashBack()
  }
}
</script>
<style scoped>
@media (max-width: 500px) {
    .x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-img-category {
        width: 45px;
        height: 45px;
    }
}
@media (max-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-menu-text .-menu-text-main {
    font-size: .8rem;
    font-weight: bold;
    font-family: 'Kanit';
    text-align: center;
    display: block;
    padding-top: 4px;
}
}
@media (min-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-menu-text .-menu-text-main {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Kanit';
    text-align: center;
    display: block;
    padding-top: 4px;
}
}
@media (min-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent {
    display: flex;
}
}
@media (max-width: 990px) {
#main_content_recommend .x-category-index, #main_content_income .x-category-index {
    padding-top: 10px;
}
}

</style>
